import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertInterceptorService implements HttpInterceptor {

  constructor(private alertService: AlertService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error desconocido';
          if (error.status === 0) {
            errorMessage = 'No se pudo conectar al servidor. Por favor, verifica tu conexión a internet o intenta nuevamente más tarde.';
          } else if (typeof error.error === 'object' && error.error !== null) {
            errorMessage = error.error.message || 'Error desconocido';
          } else if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else {
            errorMessage = `Código de error: ${error.status}\nMensaje: ${error.message}`;
          }
          this.alertService.showAlert('error', errorMessage, 5000);
          return throwError(errorMessage);
        })
      );
  }
}
