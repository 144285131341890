import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { HomeComponent } from './home/home.component';
import { MsalRedirectComponent } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: 'home',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'clientes',
    loadChildren: ()=> import('./clientes/clientes.module').then((m) => m.ClientesModule)
  },
  {
    path: 'segmentos',
    loadChildren: ()=> import('./segmentos/segmentos.module').then((m) => m.SegmentosModule)
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'auth',
    component: MsalRedirectComponent,
    /* canActivate: [MsalGuard] */
  },
  {
    path: '**', 
    redirectTo: 'home', 
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
