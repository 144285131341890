import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { ISession } from './core/models/session';
import { AuthService } from './core/services/auth.service';
import { RolService } from './core/services/rol.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mct-modulo-clientes-tesoreria';

  environment = environment.currentEnv;
  artifact = environment.releaseInfo.artifact;

  infoSession: ISession = {
    logged: false,
    profile: {
      name: '',
      username: '',
      roles: [],
    },
  };

  constructor(
    private authService: AuthService,
    private rolService: RolService,
  ) {}

  ngOnInit() {
    this.authService.initialize().subscribe(this.infoSessionObserver);
    this.authService.detectLoginSuccess().subscribe(this.infoSessionObserver);
  }

  checkoutAccount() {
    this.authService.checkoutAccount().subscribe(this.infoSessionObserver);
  }

  logIn() {
    this.authService.logIn().subscribe(this.infoSessionObserver);
  }

  logOut() {
    this.authService.logOut().subscribe(this.infoSessionObserver);
  }

  infoSessionObserver = {
    next: (infoSession: ISession) => {
      this.infoSession = infoSession;
    },
    complete: () => {},
  };
}
