import { ASIGNAR_TRADER, CLIENTES_BLOQUEADOS_ROUTE, CLIENTES_INCUMPLIDOS_ROUTE, CLIENTES_ROUTE, CREAR_CLIENTE_MX_ROUTE, LISTA_SEGMENTOS_ROUTE, MESAS, PARAMETRIZAR_TRADER, SEGMENTO_MESA, SEGMENTOS_ROUTE, TRADER_ROUTE } from "../../constants/routes";

export const rolesConfig = {
    routes: [
      {
        parentPath: CLIENTES_ROUTE,
        parentName: 'Clientes',
        icon: 'line-of-people',
        children:[
          {
            path: CREAR_CLIENTE_MX_ROUTE,
            name: 'Replicar cliente Murex',
            roles: [
              'MPT_SVN_CONSUL',
              'MPT_SVN_JEFE',
              'MPT_SVN_PARAM',
              'MPT_SVN_TRADER',
              'MPT_SVN_TRADERPARAM',
              'MPT_SVN_TI',
              'MCT_ADMIN'
            ],
          },
          {
            path: CLIENTES_INCUMPLIDOS_ROUTE,
            name: 'Clientes incumplidos',
            roles: [
              'MPT_SVN_CONSUL',
              'MPT_SVN_JEFE',
              'MPT_SVN_PARAM',
              'MPT_SVN_TRADER',
              'MPT_SVN_TRADERPARAM',
              'MPT_SVN_TI',            
              'MCT_ADMIN'
            ],
          }
        ]
        
      },
      {
        parentPath: SEGMENTOS_ROUTE,
        parentName: 'Segmentos',
        icon: 'chart-pie-2',
        children: [
          {
            path: LISTA_SEGMENTOS_ROUTE,
            name: 'Lista segmentos',
            roles: [
              'TES_SVN_CONSUL',
              'TES_SVN_TRADER',
              'MPT_SVN_JEFE',
              'MPT_SVN_PARAM',
              'MPT_SVN_TRADERPARAM',
              'MPT_SVN_TI',
              'MCT_ADMIN'
            ],
          }
        ]
      }
      /* {
        path: CLIENTES_BLOQUEADOS_ROUTE,
        parentPath: CLIENTES_ROUTE,
        parentName: 'Clientes',
        name: 'Clientes bloqueados',
        roles: [
          'MPT_SVN_CONSUL',
          'MPT_SVN_JEFE',
          'MPT_SVN_PARAM',
          'MPT_SVN_TRADER',
          'MPT_SVN_TRADERPARAM',
          'MPT_SVN_TI',            
          'MCT_ADMIN'
        ],
      }, */
     
    ],
  };

export const rolePrivileges = {
  routes: [
    {
      parentPath: CLIENTES_ROUTE,
      parentName: 'Clientes',
      path: CREAR_CLIENTE_MX_ROUTE,
      name: 'Replicar cliente Murex',
      roles: [
        'MPT_SVN_PARAM',
        'MPT_SVN_TRADER',
        'MPT_SVN_TRADERPARAM',
        'MCT_ADMIN'
      ],
    },
    {
      path: CLIENTES_BLOQUEADOS_ROUTE,
      parentPath: CLIENTES_ROUTE,
      parentName: 'Clientes',
      name: 'Clientes bloqueados',
      roles: [
        'MPT_SVN_PARAM',
        'MPT_SVN_TRADER',
        'MPT_SVN_TRADERPARAM',
        'MCT_ADMIN'
      ],
    },
    {
      parentPath: SEGMENTOS_ROUTE,
      parentName: 'Segmentos',
      path: LISTA_SEGMENTOS_ROUTE,
      name: 'Lista segmentos',
      roles: [
        'MPT_SVN_PARAM',
        'MPT_SVN_TRADER',
        'MPT_SVN_TRADERPARAM',
        'MCT_ADMIN'
      ],
    },
  ],
};
